import * as React from 'react';
import {graphql} from"gatsby";
import Layout from '../components/layout';
import FAQAccordion from '../components/FAQAccordion';
import Seo from '../components/seo';

export const query = graphql`
query faqQuery {
  faqz: markdownRemark(fileAbsolutePath: {regex: "/faqs/"}) {
    frontmatter {
      page_intro
      page_title
      faqs {
        answer
        question
      }
    }
  }
}
`;
const FaqPage = ({data}) => {
const faqz = data.faqz.frontmatter.faqs;

  const introtext = (
    <div className="introtext">{data.faqz.frontmatter.page_intro}</div>
  )

  return (
    <Layout>
      <Seo title={data.faqz.frontmatter.page_title}/>
       <div className="container" id="faqPage">
         <div className="row">
           <div className="col-12">
            <h2>{data.faqz.frontmatter.page_title}</h2>
            {data.faqz.frontmatter.page_intro !== "" ? introtext : null}
          </div>
        </div>
        {faqz.map(faq => (<FAQAccordion key={faq.question} faq={faq} />))}
       </div>
    </Layout>
  )
}

export default FaqPage