import React, {useState} from 'react';

const FAQAccordion = ({faq, i}) => {
const [showContent, setShowContent] = useState(false);

const handleClick = () => {
    setShowContent(!showContent);
  }
    return (
                <div className="accordion" id="accordion-faqs">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="accordian-header">
                        <button onClick={ handleClick } className={`accordion-button ${showContent ? '' : 'collapsed'}`} type="button" data-bs-toggle="collapse" data-bs-target="collapse1" aria-expanded={ showContent } aria-controls="collapse1">  
                            { faq.question}
                        </button>
                        </h2>

                        <div id="collapse1"className={`accordion-collapse collapse ${ showContent ? 'show' : '' }`} aria-labelledby="accordian-header" data-bs-parent="#accordionExample">
                        <div className="accordion-body" dangerouslySetInnerHTML={{__html: faq.answer }}>
                        </div>
                        </div>

                    </div>
                </div>
    )
}

export default FAQAccordion